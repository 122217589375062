import { combineReducers } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import logger from 'redux-logger'
import localesReducer from '~@Store/features/localesSlice'

const persistConfig = {
  key: 'epc',
  storage,
}

const persistedReducer = combineReducers({
  localesStore: persistReducer(
    {
      ...persistConfig,
      key: 'storeLocales',
    },
    localesReducer
  ),
})

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    process.env.NODE_ENV !== 'production'
      ? getDefaultMiddleware({ serializableCheck: false }).concat(logger)
      : getDefaultMiddleware({ serializableCheck: false }),
  devTools: process.env.NODE_ENV !== 'production',
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
//export const wrapper = createWrapper(store)
