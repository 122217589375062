import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/next/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/next/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/next/node_modules/next/dist/shared/lib/lazy-dynamic/preload-chunks.js");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/next/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/next/src/components/atoms/link-item/link-item.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/next/src/components/cms/layer-footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/next/src/components/cms/layer-header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/next/src/components/utils/CookieAxeptio.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/next/src/components/utils/GTMScript.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ReduxProvider"] */ "/opt/atlassian/pipelines/agent/build/next/src/store/provider.tsx");
